/* eslint-disable no-unused-vars */
<template>
  <div>
    <div class="text-left">
      <h4>Draft List</h4>
    </div>
    <v-row>
      <v-col cols="6" sm="6">
        <v-text-field
          label="Patient's name"
          v-model="filterName"
          hint="Patient's name"
        ></v-text-field>
      </v-col>
    </v-row>
    <div>
      <v-data-table :headers="headers" :items="casesTable">
        <template v-slot:item.caseNo="{ item }">
          <a @click="viewCase(item.caseNo)">{{ item.caseNo }}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="primary" @click="checkout(item.caseNo)">Check out </v-btn>
        </template></v-data-table
      >
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import { nullOrUndefined } from "../../../../plugins/libs.js";
//import axios from "axios";
export default {
  name: "DraftTable",
  props: {
    doctorInfo: {
      type: Object,
    },
  },
  data() {
    return {
      filterName: "",
      defaultPrice: 4000,
      url: process.env.VUE_APP_LOYALTY_API_URI,
      cases: [],
      casesTable: [],
      options: {},
      headers: [
        {
          value: "caseNo",
          text: "Case ID",
        },
        {
          value: "patient",
          text: "Patient", // this.$t("tables.headings.status")
        },
        {
          value: "mobile",
          text: "Telephone", // this.$t("tables.headings.point")
        },
        /*
        {
          value: "birthdate",
          text: "Date of birth", // this.$t("tables.headings.point")
        },
        {
          value: "race",
          text: "Race", // this.$t("tables.headings.point")
        },
        */
        {
          value: "gender",
          text: "Gender", // this.$t("tables.headings.point")
        },
        {
          value: "actions",
          text: "Actions", // this.$t("tables.headings.actions")
        },
      ],
    };
  },
  mounted() {
    //console.log(this.doctorInfo);
    if (typeof this.getInfo.doctor != "undefined") {
      this.doctor = this.getInfo.doctor[0];
    } else {
      this.doctor = {
        firstname: "m",
        lastname: "m",
        _id: "5ffc119c5ee31b45dc1e5c58",
      };
    }
    this.listCases();
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
      listDraft: "BFC/listDraft",
    }),
    prepareDraftData: () => {
      console.log("prepareData");
      //this.cases.map();
    },
  },
  methods: {
    ...mapActions({
      casesList: "BFC/listCases",
    }),
    listCases() {
      //console.log(this.listDraft);

      this.cases = this.listDraft.map((item) => {
        console.log(item);
        let _package = {
          text: "",
          value: "",
          price: 0,
          packageStatus: "None",
        };
        if (typeof item.package !== "undefined") {
          _package = item.package;
        }
        let selectPackage = "";
        if (typeof _package.text !== "undefined") {
          selectPackage = _package.text;
        }
        let status = "Draft";
        if (typeof item.caseStatus !== "undefined") {
          status = item.caseStatus.stateStatus;
        }

        return {
          caseNo: item.caseNo,
          patient: item.caseDetail.firstname + " " + item.caseDetail.lastname,
          status: status,
          selectPackage: selectPackage,
          casePackage: _package,
        };
      });
      this.casesTable = this.cases;
    },
    viewCase(caseNo) {
      this.$router.push("/doctor/case/" + caseNo);
    },
    checkout(caseNo) {
      console.log(caseNo);
      this.$router.push("/doctor/checkout/" + caseNo);
    },
  },
  watch: {
    filterName: {
      handler(val) {
        console.log(val);
        this.casesTable = this.cases.filter((item) => {
          return item.patient.indexOf(this.filterName) > -1;
        });
      },
    },
  },
};
</script>
