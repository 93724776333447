/* eslint-disable no-unused-vars */
<template>
  <div>
    <bfc-bar></bfc-bar>
    <doctor-menu></doctor-menu>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>
    <div>
      <v-container>
        <v-row align="left" justify="left">
          <!--v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn class="white--text mx-3" color="error" depressed> New case </v-btn>
          </!--v-badge-->
          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="light-green accent-4"
              depressed
              @click="viewCase = 'Completed'"
            >
              Completed
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="primary"
              depressed
              @click="viewCase = 'All'"
            >
              All Case
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="red"
              depressed
              @click="viewCase = 'Waiting for approve'"
            >
              Waiting for Approve
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="warning"
              depressed
              @click="viewCase = 'Shipped'"
            >
              Shipped
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="green"
              depressed
              @click="viewCase = 'Submitted'"
            >
              Submitted
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :content="newCaseValue"
            :value="newCaseValue"
            color="green"
            overlap
          >
            <v-btn
              class="white--text mx-3"
              color="grey lighten-1"
              depressed
              @click="viewCase = 'DraftPage'"
            >
              Draft
            </v-btn>
          </v-badge>
        </v-row>
      </v-container>
    </div>
    <div style="height: 20px"></div>
    <v-card>
      <v-card-text>
        <!-- patient info -->
        <v-card>
          <v-app-bar color="gray" style="height: 48px">
            <v-btn style="margin-top: -16px" @click="newCase"> Create new </v-btn>
            <v-spacer></v-spacer>
          </v-app-bar>

          <v-card-text>
            <allcase-table
              v-if="viewCase != 'DraftPage' && this.doctor._id"
              v-bind:doctorInfo="doctor"
              v-bind:tableName="viewCase"
            ></allcase-table>

            <draft-table
              v-if="viewCase == 'DraftPage' && this.doctor._id"
              v-bind:doctorInfo="doctor"
            ></draft-table>

            <!--submitted-table
              v-if="viewCase == 'Submitted' && this.doctor._id"
              v-bind:doctorInfo="doctor"
            ></!--submitted-table-->
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SystemBar from "../../library/system-bar/system-bar";
import DraftTable from "../draft/draft";
import AllCasesTable from "../all-case/all-case";
//import SubmittedTable from "../submitted/submitted";
import DoctorMenu from "../menubar/menubar";
//import axios from "axios";
//import { nullOrUndefined } from "../../../../plugins/libs.js";
export default {
  components: {
    "bfc-bar": SystemBar,
    "draft-table": DraftTable,
    "allcase-table": AllCasesTable,
    //"submitted-table": SubmittedTable,
    "doctor-menu": DoctorMenu,
  },
  data: () => {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      doctor: {},
      viewCase: "All",
      draftPage: false,
      waitingForApprove: false,
      fiveStep: false,
      above5Step: false,
      //tableName: [{ All: "All" }, { Submitted: "Submitted" }],
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          to: { path: "/doctor/dashboard" },
        },
      ],
      newCaseValue: 0,
      // draftValue: 0,
      // allCases: 0,
      // monitored5Step: 0,
      // monitoredMoreThan5Step: 0,
      //---- Case
      stateStatus: "",
      totalItems: 0,
      cases: [],
      options: {},
      // case header
    };
  },
  computed: {
    ...mapGetters({
      getAuth: "BFC/getAuth",
      getInfo: "BFC/getDoctorInfo",
    }),
  },
  mounted() {
    //this.doctor = this.getInfo;
    //console.log(typeof this.getInfo.doctor);
    //console.log(this.$cookies.get("Profile").doctor);
    if (typeof this.getInfo.doctor != "undefined") {
      this.doctor = this.getInfo.doctor[0];
    } else {
      //this.doctor = this.$cookies.get("Profile").doctor[0];
      this.doctor = JSON.parse(localStorage.getItem("Profile")).doctor[0];
    }

    //this.listCases();
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      allCases: "BFC/listCases",
      setAllCases: "BFC/setAllCases",
    }),
    newCase() {
      this.$router.push("new-case");
    },
    refinement(data) {
      console.log(data);
    },

    logout() {
      this.signout();
      this.$router.push("/doctor");
    },
    redirect(page) {
      this.$router.push("/doctor/" + page);
    },
  },
  watch: {
    draftPage: {
      handler(val) {
        if (val) {
          //
          this.breadcrumbs.push({
            text: "Draft List",
            disabled: true,
            href: "#",
          });
          this.stateStatus = "Draft";
        } else {
          this.breadcrumbs.pop();
        }
      },
    },
    options: {
      handler() {
        console.log(this.options);
        // let payload = {
        //   custId: process.env.VUE_APP_OGF_CUST_ID,
        //   page: this.options.page - 1,
        //   limit: this.options.itemsPerPage,
        //   filter: this.stateStatus,
        // };
        // console.log(payload);

        // this.listCases().then((response) => {
        //   //this.cases = response.data;
        //   console.log(response);
        //   this.totalItems = response.totalItems;
        // });
      },
      deep: true,
    },
  },
};
</script>
