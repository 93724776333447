var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":"5000","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_vm._v(" รับทราบ ")])]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" โปรดอย่าลืมกด Complete สำหรับเคสที่ทำเสร็จเรียบร้อยแล้วนะคะ ")]),_c('div',{staticStyle:{"text-align":"center","padding-left":"30% !important"},attrs:{"width":"100%"}},[(_vm.responseStatus.alertStatus)?_c('v-alert',{staticStyle:{"z-index":"9999","text-align":"left"},attrs:{"type":_vm.responseStatus.type,"width":"40%","dense":"","dismissible":""}},[_vm._v(" "+_vm._s(_vm.responseStatus.text)+" ")]):_vm._e()],1),_c('div',{staticClass:"text-left"},[_c('h4',[_vm._v(_vm._s(_vm.tableName))])]),_c('div'),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Patient's name","hint":"Patient's name"},model:{value:(_vm.filterName),callback:function ($$v) {_vm.filterName=$$v},expression:"filterName"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.casesTable},scopedSlots:_vm._u([{key:"item.caseNo",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.viewCase(item.caseNo)}}},[_vm._v(_vm._s(item.caseNo))])]}},{key:"item.labMonitoring",fn:function(ref){
var item = ref.item;
return [(item.totalRefinement <= 4 && item.totalRefinement - 1 > item.sequence)?_c('span',{staticStyle:{"color":"#a9a9a9"}},[_vm._v(" ( Refinement "+_vm._s(item.refCaseNo)+"/R"+_vm._s(item.sequence + 1)+" has started) ")]):_vm._e()]}},{key:"item.shippingInformation",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Shipping Name")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.shippingInformation.shippingName))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Tracking No.")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.shippingInformation.trackingNo))])],1)],1),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Shipping Date")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.shippingInformation.shippingDate))])],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" "),(item.status == 'Approved' && item.shippingInformation === undefined)?_c('span',[_vm._v("( Under fabrication )")]):_vm._e(),(item.status == 'Approved' && item.shippingInformation !== undefined)?_c('span',[_vm._v("( Shipped )")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[(
            item.status == 'Waiting for approve' &&
            (item.sequence == 0 || item.sequence == 3)
          )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.checkout(item.caseNo)}}},[_vm._v("Check out "+_vm._s(item.selectPackage)+" ")]):_vm._e(),(item.status == 'Draft' && item.sequence == 0)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.checkout(item.caseNo)}}},[_vm._v("Check out ")]):_vm._e(),(
            item.totalRefinement < 4 &&
            item.totalRefinement - 1 == item.sequence &&
            item.status == 'Approved'
          )?_c('v-btn',{on:{"click":function($event){return _vm.refinement(item.caseNo, item.sequence)}}},[_vm._v(" new Refinement ")]):_vm._e(),(
            item.totalRefinement <= 4 &&
            item.totalRefinement - 1 == item.sequence &&
            _vm.isCompleted(
              item.stepToComplete,
              item.stepUpload,
              item.caseDate,
              item.runNo
            ) &&
            item.status == 'Approved'
          )?_c('v-dialog',{attrs:{"persistent":"","max-width":"520"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","light":""}},'v-btn',attrs,false),on),[_vm._v(" Complete Case ")])]}}],null,true),model:{value:(_vm.completeDialog),callback:function ($$v) {_vm.completeDialog=$$v},expression:"completeDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirm to complete case "+_vm._s(item.caseNo)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.completeDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.setCompleteCase(item._id, item.caseNo, item.refCaseNo)}}},[_vm._v(" Complete ")])],1)],1)],1):_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openFeedback(item.caseNo, item._id, item, 'normal')},"closeFeedback":_vm.closeFeedback}},[(item.status != 'Draft' && _vm.isnewFeedback(item))?_c('v-badge',{attrs:{"color":"orange"}},[_vm._v(" open Feedback ")]):_c('v-badge',{attrs:{"value":""}},[_vm._v(" open Feedback ")])],1),(
            item.sequence < 3 &&
            item.sequence > 0 &&
            item.status == 'Waiting for approve'
          )?_c('v-dialog',{attrs:{"persistent":"","max-width":"520"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Approve proposal ")])]}}],null,true),model:{value:(_vm.approveDialog),callback:function ($$v) {_vm.approveDialog=$$v},expression:"approveDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirm to approve case "+_vm._s(item.caseNo)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.approveDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.approveProposal(item._id, item.caseNo)}}},[_vm._v(" Confirm ")])],1)],1)],1):_vm._e(),(item.status == 'Waiting for approve' || item.status == 'Approved')?[_c('div',[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue lighten-2","dark":""},on:{"click":function($event){_vm.dialog[item.caseNo] = true}}},'v-btn',attrs,false),on),[_vm._v(" Download PDF/Media ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" PDF/Media ")]),_c('v-card-text',{staticClass:"text-left"},[(item.pdfData.files[0])?_c('a',{on:{"click":function($event){return _vm.downloadFile(item.caseNo, item.pdfData.files[0].name)}}},[_vm._v(" Click to download "+_vm._s(item.pdfData.files[0].name))]):_vm._e(),_c('v-divider'),(item.pdfData.files[1])?_c('a',{on:{"click":function($event){return _vm.downloadFile(item.caseNo, item.pdfData.files[1].name)}}},[_vm._v(" Click to download "+_vm._s(item.pdfData.files[1].name))]):_vm._e(),_c('v-divider'),(item.pdfData.files[1])?_c('a',{on:{"click":function($event){return _vm.downloadMultipleFile(item.caseNo, item.pdfData)}}},[_vm._v(" Click to download all files")]):_vm._e(),_c('v-divider'),_c('v-btn',{on:{"click":function($event){return _vm.playVideo(item.caseNo, item.proposal)}}},[_vm._v("Play Video")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Close ")])],1)],1)]}}],null,true)})],1)]:_vm._e()],2)]}}])}),_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},model:{value:(_vm.loadPlayer),callback:function ($$v) {_vm.loadPlayer=$$v},expression:"loadPlayer"}},[_c('player-viewer',{attrs:{"props":_vm.mp4},on:{"closePlayer":_vm.closePlayer}})],1),(_vm.feedbackCaseId)?_c('feedback-app',{attrs:{"caseNo":_vm.feedbackCaseNo,"caseId":_vm.feedbackCaseId,"custId":_vm.custId,"dialog":_vm.feedbackDialog,"useBy":"DR"},on:{"closeFeedback":_vm.closeFeedback},model:{value:(_vm.feedbackDialog),callback:function ($$v) {_vm.feedbackDialog=$$v},expression:"feedbackDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }