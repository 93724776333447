<template>
  <v-container class="grey lighten-5">

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="8" xs="12">
        <v-card class="pa-2" outlined tile
          ><v-card-text
            ><video ref="videoPlayer" muted playsinline controls autoplay>
              <source :src="url" type="video/mp4" /></video></v-card-text
          ><v-card-actions>
            <v-btn color="primary" @click="closePlayer()"> Close </v-btn>
          </v-card-actions></v-card
        >
      </v-col>
      <v-col cols="12" md="4" sm="12" xs="12">
        <v-card class="mx-auto" max-width="100%" tile>
          <v-list dense>
            <v-subheader>Playlist (click to play)</v-subheader>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in props.sources" :key="i">
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title
                        v-text="item.name"
                        @click="playVideo(i)"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.name }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {
    //
  },
  props: {
    props: {
      type: Object,
    },
  },
  data() {
    return {
      url: "",
      autoplay: "autoplay",
      isPlay: true,
      //vm: null,
      //player: null,
      selectedItem: 0,
      items: [],
    };
  },
  computed: {

          },
  mounted() {
    console.log("mounted");
    //console.log(this.props);
    this.items = this.props.sources;
    
    this.url = this.props.sources[0].url;
     //let vm = this;
    // console.log(vm.$refs.videoPlayer);
    //let vm = this;
    //vm.selectedItem = 0;
    //console.log(this.items);
   
    this.$refs.videoPlayer.addEventListener("ended", () => {
      console.log("ended");
      //console.log(vm)
      //console.log(this.props.sources.length);
      //console.log(this);

      this.selectedItem =
        this.selectedItem >= this.props.sources.length - 1 ? 0 : this.selectedItem + 1;
      //console.log(this.selectedItem);
      /*this.url = this.props.sources[this.selectedItem].url;
      vm.$refs.videoPlayer.load();
      vm.$refs.videoPlayer.play();*/
      this.playVideo(this.selectedItem);
    });
  },
  created(){
                console.log("created");
          },
  destroyed(){
                console.log("destroyed");
          },
  updated(){
          console.log("updated");
          //console.log(this.items);
          //console.log(this.props);
          //this.selectedItem = 0;
          //this.selectedItem =
          //this.selectedItem >= this.props.sources.length ? 0 : this.selectedItem + 1;
          //this.$refs.videoPlayer.play();
          //if(!this.isPlay){
                //this.selectedItem = 0;
                //this.url = this.props.sources[this.selectedItem].url;
                //this.isPlay = !this.isPlay;
                

          //}
  },
  methods: {
    playVideo(index) {
      //let vm = this;
      //vm.$refs.videoPlayer.addEventListener("ended", () => {
      console.log("Play")
      console.log(index);
      this.isPlay = true;
      //this.selectedItem = parseInt(index);
      //console.log(this.selectedItem);
      this.url = this.props.sources[index].url;
      this.$refs.videoPlayer.load();
      this.$refs.videoPlayer.play();
      //});
    },
    playLoop(index) {
      console.log(index);
      //let vm = this;
    },
     closePlayer() {
      console.log("closePlayer");
      this.props = {};
      this.items = [];
      //console.log(this.props);
      //console.log(Object.entries(this.props).length)
      this.$refs.videoPlayer.pause();
      //this.selectedItem = 0;
      
      //console.log(this.isPlay);
      this.isPlay = false;   
      this.$emit("closePlayer", "");
    },
  },
  watch:{

        props: {
                handler(newVal ,  oldVal){
                        //console.log(oldVal);
                        //console.log(newVal);
                        /*this.items = newVal.sources;
                        console.log(this.items)
                        */
                        if(oldVal.sources !== newVal.sources){
                                        this.selectedItem = 0
                                        this.playVideo(this.selectedItem);
                                }
                        }
        }
  }
  
};
</script>
<style>
video {
  width: 100%;
  min-width:400px;
}
</style>
